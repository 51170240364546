import React, { FC } from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'
import Navigation from '../components/navigation'
import { StaticImage } from 'gatsby-plugin-image'

const HeroSection = styled.div`
	position: relative;
	width: 100%;
	background-color: ${p => p.theme.colors.yves};
	color: ${p => p.theme.colors.silver};
`

const Content = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding-left: 2rem;
	padding-right: 2rem;

	h1 {
		margin-top: 0;
		margin-bottom: 1rem;
		font-weight: normal;
	}

	section {
		padding: 2rem 0;
	}

	h1 {
		padding-top: 2rem;
		margin-bottom: 1rem;
	}
`

const ImageRow = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	margin: 0 -0.5rem;
	@media only screen and (max-width: 720px) {
		flex-wrap: wrap;
	}
`

const Image = styled.div`
	flex: 1 1 50%;
	padding: 0.5rem;
`

const VenuePage: FC<{}> = () => (
	<>
		<SEO title="Venue" />
		<HeroSection>
			<Content>
				<Navigation />
				<ImageRow>
					<Image>
						<StaticImage src="../images/castle_ext.jpg" alt="Castle exterior" />
					</Image>
					<Image>
						<StaticImage src="../images/castle_int.jpg" alt="Castle interior" />
					</Image>
				</ImageRow>
				<p style={{ opacity: 0.4 }}>Source: zigzagprinromania.com, dli.ro</p>
				<h1>RO - Locatia</h1>
				<p>
					Petrecerea va avea loc la superbul Castel Károlyi din Carei, în
					apropiere de Satu Mare.
				</p>
				<p>
					Castelul îşi are originile în anul 1482. A inceput ca o casa de piatrã
					(ha!) a familiei nobile Károlyi. Din cauza pericolului turcesc
					clădirea este înconjurată de bastioane şi un şant cu apă,
					transformându-se în cetate. Dupã trecerea pericolului turcesc,
					castelul este reconstruit in 1792. Zidul de apărare este demolat,
					şantul umplut şi castelul este înconjurat cu un parc englezesc, care
					acum este parcul central al Careiului. În 1893 castelul este
					reconstruit din nou, având ca model castelele de pe valea Loarei.
					Curtea interioară de formă pătrată a fost acoperită, dând naştere unui
					atriu central cu două nivele. Cele două şemineuri italieneşti din
					atriu sunt decorate cu figuri de lei care se regăsesc şi în blazonul
					familiei.
				</p>
				<p style={{ opacity: 0.4 }}>Sursa: welcometoromania.ro</p>

				<h1>EN - Venue</h1>
				<h2>Satu Mare</h2>
				<p>
					We can't wait to welcome you to our corner of Romania. The
					north-western corner that is! Both Laura and Emil, as most of you
					know, are from Satu Mare and the city will be at the center of the
					wedding weekend and where we'll all stay on Friday and Saturday
					evening.
				</p>
				<p>
					Satu Mare county lies in a richly multi-ethnical region at the western
					edge of Transylvania. Technically it's not part of Transylvania
					proper, but rather of the Maramureş cultural region, although we don't
					like to admit that since Maramureş is the neighbouring and rival
					county. Classic continental Europe shenanigans. It's an area where
					Romanian culture mixes greatly with that of our Hungarian and
					Ukrainian neighbours, and it's historically been home to a large
					Jewish community that has now mostly relocated to Williamsburg, New
					York.
				</p>
				<h2>Carei</h2>
				<p>
					The wedding party will take place at the beautiful Károlyi Castle in
					neighbouring Carei.
				</p>

				<p>
					The castle traces it's origins back to 1482, as the base of the
					Károlyi noble family. Because of Ottoman advances, the original noble
					house was enhanced with a water filled moat and surrounded by an
					outter wall with towers becoming a castle. Once the Ottoman danger
					went away, it was reconstructed in 1792. The castle shed it's outer
					defensive wall and was surrounded with an English garden which is now
					a public park. It was rebuilt again in 1893 to the style of the Loire
					Valley castles. The square inner court was covered, giving birth to a
					central atrium organized on two levels. The two Italian fireplace
					surrounds in the atrium feature lion motifs also present on the family
					crest.
				</p>

				<br />
			</Content>
		</HeroSection>
	</>
)

export default VenuePage
